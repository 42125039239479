<!--供应商中心-->
<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>
    <div style="background-color: #f5f5f5;">
      <div class="content_c">
        <el-tabs :tab-position="tabPosition" v-model="defaultName" @tab-click="handleClick">
          <el-tab-pane :disabled="true">
            <span slot="label"><img style="
    width: 30px;
    height: 6px;" src="../assets/center/left.png" alt=""> 交易平台 <img style="
    width: 30px;
    height: 6px;" src="../assets/center/right.png" alt=""></span>
          </el-tab-pane>
          <el-tab-pane label="合同汇总">


       

            <div class="contract_summary"><contract-summary></contract-summary></div>

          </el-tab-pane>
          <el-tab-pane label="供应商信息">


            <div class="contract_detils"><contractDetils></contractDetils></div>

          </el-tab-pane>
       
        </el-tabs>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import contractSummary from "./components/contractSummary";
import contractDetils from "./components/contractDetils";
import { province, menuList, getInfo, listPain } from "../api/api";


export default {
  components: {
    topNav,
    bottom,
    contractSummary,
    contractDetils
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  data() {
    return {
      optionList: [
        {
          title: "合同汇总",
        },
        {
          title: "供应商信息",
        }
      ],
      option: 0,
      list: [],
      pageCount:20,
      tabPosition: "left",
      newsList: [],
      leftList: [],
      
      detailsItem: {},
      defaultName: '1'
    };
  },
  mounted(){

    this.init();
  },
  methods: {
    selectOption(index) {
      console.log(index,'123');
      this.option = index;
    },
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        // console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async init() {
      let res = await menuList();
      // console.log(res.Data[1].childs, "res");
      if (res.ResultCode == 0) {
        this.leftList = res.Data[1].childs.slice(1);
        // console.log(this.leftList[0], 999);
        this.getDate(this.leftList[0].pid);
      }
    },
    async details(e) {
      let res = await getInfo({
        pid: e,
      });
      if (res.ResultCode == 0) {
        // console.log(res.Data, "res详情");
        this.detailsItem = res.Data;
      }
    },
    async handleClick(tab) {
      console.log(tab.index, event, this.leftList[tab.index]);
      this.getDate(this.leftList[tab.index - 2].pid);
    },
    async getDate(e) {
      // console.log(e, "e");
      let pids = "";
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        // console.log(res.Data, "res列表");
        pids = res.Data.Items[0].pid;
      }
      this.details(pids);
    },
    
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  .nav_top {
    width: 100%;
    height: 200px;
    background-image: url("../assets/banner3.png");
    background-repeat: no-repeat;
    background-size: 100% 200px;
    box-sizing: border-box;
    
    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';

      transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }
    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
  }
  .content_c {
    width: 87.5rem;
    // height: 1000px;
    margin: 0 auto;
    padding-top: 4.5rem;
    padding-bottom: 3.125rem;
    .contract_summary{
background-color: #fff;
// min-height: 500px;
padding: 20px;
margin: 30px 70px;
    }
    .contract_detils{
background-color: #fff;
margin: 0 50px;
    }

  }


}

::v-deep {
  .el-tabs__content {
    // background-color: #fff;
  }

  .el-tabs__nav.is-left {
    width: 150px;
    background-color: #fff;
  }

  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
  }

  .el-tabs__item:hover {
    background-color: rgba(36, 119, 228, .1);
  }

  .el-tabs__item.is-active {
    background-color: rgba(36, 119, 228, .1);

  }

  #tab-0 {
    background-color: #356CEB;
    color: #fff;

  }

  .el-tabs__active-bar.is-right {}
}
</style>
