<!--合同汇总-->
<template>
  <div class="box">
    <div class="btn" @click="toAdd">新增</div>
    <div class="right_content">
      <div class="list_style2" v-for="(item, index) in list" :key="index">
        <div class="left2">
          <div class="title3">{{ item.name }}</div>
          <div class="word">
            甲方：{{ item.partyA||''}}&nbsp;&nbsp;&nbsp;&nbsp; 乙方：{{ item.partyB||''}} &nbsp;&nbsp;&nbsp;&nbsp;签约时间:{{
              item.contractTime.slice(0,10)||''
            }}
            &nbsp;&nbsp;&nbsp;&nbsp;签约金额：{{ item.contractAmount||''}}元
          </div>
          <div class="file" @click="downFile(item)">
            <div class="pdf_img">
              <img src="../../assets/zip.png" width="100%" height="100%"/>
            </div>
            <div class="pdf_name">{{ item.name }}-合同招投标压缩文.zip</div>
          </div>
        </div>
        <div class="right2">
          <div class="icon" @click="downFile(item)">
            <img src="../../assets/supplierCenter/3.png" />
          </div>
          <div class="icon" @click="seeOrder(item)">
            <img src="../../assets/supplierCenter/1.png" />
          </div>
          <div class="icon" @click="deleteOrder(item)">
            <img src="../../assets/supplierCenter/2.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="right_bom">
      <el-pagination
        background
        layout="prev, pager, next"
          :current-page="page"
          :page-size="limit"
        :total="pageCount"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {myOrder} from '../../api/api'
import {remove} from '../../api/api'
import {winrar} from '../../api/api'

export default {
  data() {
    return {
      list: [],
      pageCount: 0,
      page:1,
      limit:10,
      // Url: "http://192.168.0.26:8075",
      Url:'http://cjsupplier.9czn.cn',
    };
  },beforeMount(){
    this.getMyOrder()
  },mounted(){
  },methods:{
    //跳转查看订单详情
    seeOrder(item){
      localStorage.setItem('orderDetails',JSON.stringify(item))
      this.$router.push('/orderEcho')
    },
    //打包
    downFile(item){
      this.getPack(item)
    },
    //获取压缩包
    async getPack(item){
      let res = await winrar({
        pid:item.pid
      })
      if(res.ResultCode == 0){
          console.log(res,'res')
          window.location.href = res.ResultInfo;
      }
    },
    //跳转新增页面
    toAdd() {
      this.$router.push("/dataUpload")
    },
    //修改页码
    changePage(e){
      this.page = e
      this.getMyOrder()
    },
    //获取我的订单
    async getMyOrder(){
      let res = await myOrder({
        page:this.page,
        limit:this.limit,
      })
      if(res.ResultCode == 0){
        res.Data.Items.forEach((tmp) => {
            tmp.createTime = tmp.createTime.slice(0,10)
        });
        this.list = res.Data.Items
        this.pageCount = res.Data.TotalRecordCount
      }
    },
    //删除订单
    deleteOrder(item){
      let that = this
      that.$confirm('确认删除订单', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.getRemove(item.pid)
        }).catch(() => {          
        });
    },
    //
    async getRemove(id){
      console.log(id,'id')
      let res = await remove({
        id:id
      })
      if(res.ResultCode == 0){
        this.getMyOrder()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  .btn {
    width: 5rem;
    // height: 2rem;
    background: #2477e4;
    font-size: 1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    // line-height: 2rem;
  }
  .right_content {
    .list_style2 {
      width: 100%;
      background: #eef5ff;
      margin-top: 1.5625rem;
      padding: 1.25rem;
      display: flex;
      justify-content: space-between;
      .left2 {
        width: 80%;
        .title3 {
          font-size: 1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        }
        .word {
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-top: 1.4375rem;
        }
        .file {
          display: flex;
          align-items: flex-end;
          margin-top: 1.25rem;
          .pdf_img {
            width: 1.375rem;
            height: 1.375rem;
          }
          .pdf_name {
            font-size: .75rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2477e4;
            margin-left: .6875rem;
          }
        }
      }
      .right2 {
        .icon {
          width: 1.375rem;
          height: 1.375rem;
          margin-top: .5625rem;
          img {
            width: 1.375rem;
            height: 1.375rem;
          }
        }
      }
    }
  }
  .right_bom {
    margin-top: 1.25rem;
  }
}

</style>
